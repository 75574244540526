import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"

const imggmailaliasa = "../../images/guides/gmailalias.png"
const imggmailaliasb = "../../images/guides/gmailalias2.png"

const imgyahooaliasa = "../../images/guides/yahooalias.png"
const imgyahooaliasb = "../../images/guides/yahooalias2.png"


const EmailAliasPage = () => {
	return (

	<Layout activePath='guides/emailalias' pageTitle="e-mail Alias" description="Setup multiple alias in your email account"  meta={[{name: `robots`, content: `noindex`}]}>
		<div className="margin-content">
			<h1>e-mail Alias</h1>
			<h3>Why?</h3>
			Email alias allow people to use different email addresses/identities when sending out emails.  If used together with mail forwarding, this will allow the person to consolidate all messages into one inbox.
			<h3>How?</h3>
			The following are ways to create email aliases using popular email providers:
			<ul>
				<li><b>Gmail</b> (as of 10/11/2012)</li>
				<ol>
					<li>Go to <b>Settings</b><br/>
						<StaticImage layout="fullWidth" src={imggmailaliasa} alt={""} />
					</li>
					<li>Click <b>Accounts and Imports</b> Tab</li>
					<li>Look for <b>Send mail as:</b> section</li>
					<li>Click <b>Add another email address you own</b><br/>
						<StaticImage layout="fullWidth" src={imggmailaliasb} alt={""} />
					</li>
					<li>Fill up the necessary details.
						<ul>
							<li>An SMTP account might be requested, please consult your system administrator for this.</li>
						</ul>
					</li>
					<li>A verification code will be sent to the email address to confirm ownership.</li>
					<li>If successful, you can now select the email address as your "From" address whenever you compose/reply to mail.</li>
				</ol>
				<li><b>Yahoo</b> (as of 10/11/2012)</li>
				<ol>
					<li>Go to <b>Options-&gt;Mail Options</b><br/>
						<StaticImage layout="fullWidth" src={imgyahooaliasa} alt={""} />
					</li>
					<li>Click <b>Mail Accounts</b></li>
					<li>Click <b>+ Add</b><br/>
						<StaticImage layout="fullWidth" src={imgyahooaliasb} alt={""} />
					</li>
					<li>Fill up the necessary details.</li>
					<li>A verification code will be sent to the email address to confirm ownership.</li>
					<li>If successful, you can now select the email address as your "From" address whenever you compose/reply to mail.</li>
				</ol>
			</ul>
			<h3>Related options</h3>
			<ul>
				<li><i>Reply-to address</i>: Change this if you want recipients to respond using a different address</li>
				<li><i>Receiving Mail</i>: Some systems allow you to get messages from other servers so you can get messages sent to that address</li>
				<li><i>Make default</i>: Use the said email as your default identity when composing a new message</li>
				<li><i>Auto-forward</i>: If the server supports this, you can auto-forward incoming messages to your preferred inbox.</li>
			</ul>
		</div>

	</Layout>
	)
}

export default EmailAliasPage